import axios, { AxiosRequestHeaders, AxiosResponse } from "axios";
import Helper from "../Helpers/Helper";
type HTTPPArams = Record<string, string | number | boolean>
const BASE_URL = '/';

const AXIOS = axios.create({
    baseURL: BASE_URL,
    responseType: 'json' as const,
    transformResponse: [function (data) {
        try {
            return JSON.parse(data)
        } catch {
            return { Success: false, ExceptionMessage: 'Internal error' }
        }
    }],
});

const getAxiosHeaders = async (newHeaders?: Record<string, string>) => {
    if (newHeaders !== undefined) {
        return { ...await Helper.getHeaders(), ...newHeaders } as any as AxiosRequestHeaders
    } else {
        return { ...await Helper.getHeaders() } as any as AxiosRequestHeaders
    }
}
const sessionHeaders = async (avoidSiteParam: boolean = false) => {
    return {
        ApiKey: "-1",
        Username: window.sessionStorage.getItem('username'),
        User: window.sessionStorage.getItem('username'),
        SK: "-1",
        AccountId: "-1",
        //Token: window.sessionStorage.getItem("authToken"),
        Site: avoidSiteParam ? "" : await Helper.getSite(),
        Path: avoidSiteParam ? "" : await Helper.getSite(),
        Autoprovision: true
    }
}
interface IHttpClientResponse {
    Result: string
    Success: boolean
}

async function Scramble(url: string, params: any, method: string) {
    const blackList: string[] = [
        'POST_GetSetrun',
        'GET_Configuration',

        // GraphApiController
        'POST_GraphApi/ConsentUrl',
        'POST_GraphApi/Consent',
        // TrustController
        'POST_Trust/Callback',

        // ApplicationContextController
        'GET_ApplicationContext',
        // AuthenticateController
        'GET_isAuthenticated',
        'GET_verify',
        'GET_teamslogin',
        'POST_Authenticate',

        // OutboundIntegrationController
        'POST_OutboundIntegration/OAuth/Token',

        // PaymentController
        'POST_payment/create-checkout-session',
        'GET_payment/orderSuccess',
        'GET_payment/webhook',
        'POST_payment/RedirectPortal',
        // EsignController
        'GET_DocusignListen',
        'POST_DocusignListen',
        // RegisterController
        'GET_setregistration',
        'GET_getregistration',
        'GET_resendcode',
        'GET_ContactSupport',
        // ExternalLinksAccess
        'POST_ExternalLinks'
    ]

    const scope: string[] = [
        // DocumentOnlyAlertRenewalController
        'GET_DocumentOnlyAlertRenewal/GetContextRenewals',
        'GET_DocumentOnlyAlertRenewal/GetRenewalCount',
        'POST_DocumentOnlyAlertRenewal/UpdateRecordDefinition',
        'POST_DocumentOnlyAlertRenewal/CreateRecordDefinition',
        'POST_DocumentOnlyAlertRenewal/DeleteRecordDefinition',
        'GET_DocumentOnlyAlertRenewal/GetContextRecord',
        'GET_DocumentOnlyAlertRenewal/GetContextTemplate',
        'POST_DocumentOnlyAlertRenewal/CreateTemplateDefinition',
        'POST_DocumentOnlyAlertRenewal/DeleteTemplateDefinition',
        'POST_DocumentOnlyAlertRenewal/UpdateTemplateDefinition',

        // EmailTemplateNotificationsController
        'GET_EmailTemplateNotifications/GetTemplateStatus',
        'POST_EmailTemplateNotifications/UpdateTemplateStatus',
        'POST_EmailTemplateNotifications/DeleteTemplateDefinition',
        'GET_EmailTemplateNotifications/GetTemplateDefinition',
        'POST_EmailTemplateNotifications/CreateUpdateTemplateDefinition',

        // PlanController
        'GET_Plan/GetAllPlan',
        'GET_Plan/GetPlanById',
        'GET_Plan/Evaluate',
        'POST_Plan/CreateUpdate',
        'GET_Plan/Delete',

        // DownloadController
        'GET_Download',
        'GET_Download/DocumentOnlyDataPoint',
        'GET_Download/DocumentOnlyPackage',
        'POST_Download/BulkDownload',

        // ManageClientPlans
        'GET_ManageClientPlans/GetAllManageClientPlans',
        'POST_ManageClientPlans/CreateUpdateManageClientPlans',
        'GET_ManageClientPlans/Delete',
        'POST_ManageClientPlans/CreateUpdateManageClientPlansOwnerName',

        // PlanUsageController
        'GET_GetTemplateUsage',
        'GET_GetPlanUsageByYearMonth',

        // SearchController
        'GET_GetSearchTemplatesList',
        'POST_AdvanceSearch',
        'GET_SearchFTI',

        // EmailController
        'POST_SendSMTPMailHtml',
        'POST_SendMailHtml',
        'POST_Unsub',
        'POST_ContactSupport',

        // DocumentOnlyExpressionController
        'GET_GetExpressionList',
        'GET_GetExpressionsByTemplate',
        'POST_AddExpression',
        'POST_UpdateExpression',
        'POST_DeleteExpresion',
        'GET_GetDateFormats',
        'GET_GetDateUnits',
        'GET_GetContentControls',

        // DocumentOnlyReassignRecordController
        'POST_DocumentOnlyReassignRecord',

        // DocumentOnlyReferenceNumberController
        'GET_DocumentOnlyReferenceNumber',
        'GET_DocumentOnlyReferenceNumber/Delete',
        'POST_DocumentOnlyReferenceNumber/Create',
        'POST_DocumentOnlyReferenceNumber/Update',

        // DocumentOnlyChangeOwnerController
        'POST_ChangeOwner',

        // CategoryController
        'POST_RenameCategory',

        // UserController
        'GET_getuserprofile',
        'POST_updateuserprofile',
        'GET_AccessCode',

        // GraphApiController
        'POST_GraphApi/Resend',
        'POST_GraphApi/Test',
        'POST_GraphApi/Delete',
        'POST_GraphApi',

        // AuthCodeController
        'POST_AuthCode/Send',
        'POST_AuthCode/Validate',

        // ApplicationServiceEmailController
        'POST_UpdateServiceEmail',
        'GET_GetServiceEmailByOwner',
        'POST_GetServiceEmailByTemplateIdAndOwner',
        'POST_GetServiceEmail',

        // MerchantController
        'GET_Merchant/GetAllMerchant',
        'GET_Merchant/Delete',
        'POST_Merchant/CreateUpdate',

        // DocumentOnlyTemplateSignatureController
        'GET_GetDocumentOnlyCustomUserSignatureByAudienceMaster',
        'GET_GetDocumentOnlyCustomUserSignatureById',
        'GET_GetListSignatureByAudieceMasterId',
        'POST_UpdateDocumentOnlyCustomUserSignature',
        'POST_CreateDocumentOnlyCustomUserSignature',
        'GET_DeleteDocumentOnlyCustomUserSignature',
        'GET_GetAllSignaturesOptionList',

        // ConfigurationController
        'GET_GetReleaseNotes',
        'GET_GetAllReleaseNotes',

        // ListController
        'GET_GetListMasterByUser',
        'GET_GetListsDetails',
        'POST_CreateListMaster',
        'GET_DeleteListMaster',
        'POST_UpdateListMaster',
        'POST_CreateListDetails',
        'GET_DeleteListDetails',
        'POST_UpdateListDetails',
        'POST_ClonListMaster',
        'POST_AddListCSV',

        // AccountController
        'POST_Account/ResetPassword',

        // ActivityController
        'GET_getactivity',

        // ContentControlController
        'POST_ContentControl',

        // ApiIntegrationController
        'POST_ApiIntegration/CreateCertificate',
        'POST_ApiIntegration/UpdateCertificate',
        'GET_ApiIntegration/Certificates',
        'POST_ApiIntegration/DeleteCertificate',

        // BillingStateController
        'GET_BillingState/GetAllBillingStateOwnerHistory',
        'GET_BillingState/GetAllBillingStatebyOwnerName',
        'POST_BillingState/CreateUpdateOwnerName',

        // DocumentOnlyLoggingController
        'POST_DocumentOnlyLogging',
        'GET_DocumentOnlyLogging/Catalogs',
        'GET_DocumentOnlyLogging/ApplicationLogs',
        'GET_DocumentOnlyLogging/ApiEventLogs',
        'GET_DocumentOnlyLogging/ApiEventLogs/Status',
        'POST_DocumentOnlyLogging/ApiEventLogs/Activate',

        // OutboundIntegrationController
        'GET_OutboundIntegration/ConfigurationTemplateAll',
        'GET_OutboundIntegration/GetEventsByTemplateId',
        'GET_OutboundIntegration/ConfigurationTemplate',
        'GET_OutboundIntegration/Integration/Delete',
        'POST_OutboundIntegration/ConfigurationTemplate/Update',
        'POST_OutboundIntegration/Event/Update',
        'POST_OutboundIntegration/ConfigurationTemplate/Delete',
        'POST_OutboundIntegration/ConfigurationTemplate',
        'POST_OutboundIntegration/Integration/Update',
        'POST_OutboundIntegration/Integration',
        'POST_OutboundIntegration/Script/Execute',
        'POST_OutboundIntegration/Script/Compile',
        'GET_OutboundIntegration/Integration/Approve',
        'GET_OutboundIntegration/Event/OnLoad',
        'GET_OutboundIntegration/GetIntegrationsByTemplateId',
        'GET_OutboundIntegration/Integration/Execute',
        'POST_OutboundIntegration/Integration/Execute',
        'GET_OutboundIntegration/Event/Execute',
        'POST_OutboundIntegration/Event/Execute',
        'POST_OutboundIntegration/Integration/TestOAuth20',
        'POST_OutboundIntegration/Event',
        'GET_OutboundIntegration/Event/Delete',
        'GET_OutboundIntegration/Event/Manual',
        'GET_OutboundIntegration/Event/OnClick',

        // PaymentController
        'POST_payment/updateSubscription',
        'POST_payment/cancelSubscription',

        // PackageController
        'GET_Package/ImportGUID',
        'POST_Package/Import',

        // DocumentOnlyTemplateSignatureController
        'POST_CreateTemplateSignature',
        'POST_DeleteTemplateSignature',
        'GET_GetTemplateSignature',
        'GET_GetAllSignaturesImages',
        'POST_ApplySignature',

        // EsignController
        'POST_SendMailEsign',
        'POST_AbortEsign',
        'GET_GetSigners',
        'GET_GetStatus',
        'GET_GetDocumentOnlySignatureWithOutContentByDoc',
        'GET_GetDocumentOnlySignatureWithContentByDocAndSignature',
        'GET_DeteleDocumentOnlySignatureById',
        'GET_GetIntegrationKey',

        // StateManagementController
        'GET_statemanagement',
        'POST_statemanagement',
        'POST_UpdateTemplateEntity',
        'POST_UpdatetUserEntity',
        'POST_SaveDisclaimer',
        'GET_GetTemplates',
        'GET_GetTokenDocuSign',
        'GET_GetDisclaimer',
        'POST_SetTokenDocuSign',

        // SSO
        'GET_Captcha',
        'GET_Captcha/Verify',
        'GET_ValidateUser',
        'GET_Validate/SSO',
        'GET_logoutSSO',
        'GET_Saml/Provider',
        'GET_Saml/Validate',
        'POST_Saml/Create',

        // SubFormController
        'GET_DocumentOnlyTemplateSubForms',
        'POST_CreateSubRecord',
        'GET_GetSubRecords',
        'POST_SubformRestriction',
        'GET_SubformRestriction',
        'GET_SubformRestrictionInfo',

        // DocumentOnlyAttachmentController
        'GET_GetDocumentDraftAttachmentBlob',
        'GET_GetDocumentOnlyAttachmentById',
        'GET_DeleteDocumentDraftAttachment',
        'GET_DeleteDocumentOnlyAttachment',
        'GET_GetDocumentDraftAttachmentById',
        'GET_GetDocumentOnlyAttachmentByDocumentId',
        'POST_AddDocumentDraftAttachment',
        'POST_AddDocumentAttachment',

        // DocumentAssemblyController
        'GET_DocumentAssembly',
        'GET_DocumentAssembly/Rules',
        'POST_DocumentAssembly',
        'POST_UpdateDocumentAssembly',
        'POST_DocumentAssembly/Action',
        'POST_UpdateDocumentAssembly/Action',
        'GET_OrderDocumentAssembly/Rule',
        'GET_OrderDocumentAssembly/Action',
        'GET_DeleteDocumentAssemblyRule',
        'GET_DeleteDocumentAssembly/Action',
        'GET_ContentLibrary',
        'POST_ContentLibrary',
        'POST_UpdateContentLibrary',
        'GET_DeleteContentLibrary',
        'GET_DeleteContentLibraryOption',
        'GET_DocumentLibrary',
        'POST_DocumentLibrary',
        'POST_UpdateDocumentLibrary',
        'GET_DownloadDocumentLibrary',
        'GET_DeleteDocumentLibrary',

        // DocumentOnlyCampaignController
        'GET_GetCampaignPrefillSpecificationFile',
        'GET_GetCampaignPrefillFile',
        'POST_UploadAndProcessCampaignPrefill',
        'POST_DeleteSMTPEmail',
        'POST_DeleteRespondents',
        'POST_CreateUpdateSMTPEmail',
        'POST_SendSMTPEmail',
        'GET_GetSMTPEmailTemplateId',
        'POST_CreateCampaignDetailBatch',
        'GET_GetCampaignDetailByMasterId',
        'GET_SendReminders',
        'POST_RestartCampaign',
        'GET_GetCampaignUserDetailInformationByCampaignUserGUID',
        'GET_GetCampaignDetailInformationByUsernameCampaignId',
        'GET_GetCampaignDetailGUID',
        'GET_GetCampaignIncomplete',
        'GET_GetCampaignDetailByUsername',
        'GET_GetCampaignDetailByPage',
        'GET_CampaignAccessCode',
        'GET_GetCampaignAccessCodeById',
        'GET_GetCampaignDetailById',
        'GET_GetCampaignByTemplateId',
        'GET_GetCampaignById',
        'POST_CreateCampaignDetail',
        'POST_UpdateCampaignDetail',
        'GET_DeleteCampaignDetail',
        'POST_CreateAccessCode',
        'POST_UpdateAccessCode',
        'POST_CreateCampaign',
        'POST_UpdateCampaign',
        'GET_DeleteCampaign',
        'GET_GetCampaignDefaults',

        // AudienceController
        'GET_DocumentOnlyAudienceDetailWithOwner',
        'GET_GetAudienceMasterByTemplateId',
        'GET_GetAudienceMasterByOwner',
        'GET_GetBillingClientAudiences',
        'GET_GetAudienceMasterById',
        'GET_DeleteDocumentOnlyAudienceDetail',
        'POST_CreateAudienceMaster',
        'POST_UpdateAudienceMaster',
        'GET_DeleteAudienceMaster',
        'POST_AddUserListtoAudienceCSV',
        'GET_UpdateDocumentOnlyAudienceDetail',
        'GET_CreateDocumentOnlyAudienceDetail',
        'GET_AudienceRolesAvailabilityByNewUser',
        'GET_DocumentOnlyAudienceDetailByAudienceMasterId',
        'GET_DocumentOnlyAudienceDetailByUserName',

        // TemplateController
        'POST_updatePropertyBag',
        'GET_getCloneTemplate',
        'GET_CreateExample',
        'GET_GetCultures',
        'POST_SendNotification',
        'GET_GetTemplatePDF',
        'POST_SendTemplateLink',
        'GET_RetrieveTemplateVersion',
        'POST_RestoreTemplateVersion',
        'GET_GetTemplateVersionLayout',
        'GET_getCategoriesTemplate',
        'GET_getDocumentOnlyCategories',
        'GET_getDocumentOnlyTemplateID',
        'GET_getDocumentOnlyTemplateIDBasic',
        'POST_UpdateTemplate',
        'GET_GetTemplateMetadata',
        'POST_UploadTemplate',
        'GET_getTemplateSystem',
        'GET_GetExampleTemplates',
        'GET_ExportApplication',
        'GET_DownloadDocumentOnlyTemplate',
        'GET_getListDocumentOnlyTemplate',
        'GET_DeleteDocumentOnlyTemplate',
        'GET_gettemplatelayout',
        'GET_gettemplatechildlist',
        'GET_gettemplatelist',
        'GET_AutomaticallySubFormTemplates',
        'POST_AutomaticallySubFormTemplates',
        'POST_importTemplate',

        // RegisterController
        'GET_deleteregistration',
        'GET_DeleteUserbyAdminRequest',

        // DocumentController
        'POST_GetPreviewDocumentBeforeSubmit',
        'GET_GetDocumentImages',
        'GET_GetDocumentDraftImages',
        'POST_CloneDocument',
        'POST_MakeCurrentVersion',
        'POST_SendLinkDocument',
        'GET_DocumentOnlyStatistics',
        'GET_UndoCheckoutDocumentOnly',
        'POST_UpdateDocumentOnly',
        'GET_CheckinCheckoutDocumentOnly',
        'GET_DocumentOnlyDeleteNote',
        'POST_DocumentOnlyAddNote',
        'GET_DocumentOnlyNote',
        'POST_AddAllowedUser',
        'GET_DeleteAllowedUser',
        'GET_DocumentOnlyStatus',
        'POST_DocumentOnlyClose',
        'POST_DocumentOnlySuspend',
        'POST_DeleteContentControl',
        'GET_DeleteDocument',
        'GET_GetDocumentVersion',
        'GET_DeleteDocumentVersion',
        'GET_GetDocumentVersionPreview',
        'GET_GetDocumentPreview',
        'GET_GetDocument',
        'GET_AudienceIntersect',
        'GET_GetDocumentlist',
        'POST_CreateDocument',
        'GET_DocumentDraft',
        'POST_DocumentDraft',
        'POST_AddContentControl',

        //KeepAlive
        'POST_DocumentOnlyExtendSession'
    ]

    const _sessionHeaders = await sessionHeaders((url === "Configuration"))
    let _params = { ..._sessionHeaders, ...params }

    if (params !== undefined && params !== null && typeof params === "object") {

        if (scope.find(item => `${method}_${url}`.toLowerCase() === item.toLowerCase()) && !blackList.find(item => `${method}_${url}`.toLowerCase() === item.toLowerCase())) {
            await post('GetSetrun', { value: JSON.stringify(_params) }, await getAxiosHeaders())
                .then((data: IHttpClientResponse | any) => {
                    if (data.data.Success) {
                        _params = { value: data.data.Result }
                    }
                })
        }
    }
    return _params
}

const getWithSession = async <T>(url: string, params: any, signal?: AbortSignal | undefined): Promise<void | AxiosResponse<T, any>> => {
    /* region Scramble */
    const _params = await Scramble(url, params, 'GET')
    /* endregion Scramble */

    return AXIOS.get<T>(url, {
        signal: signal,
        params: _params,
        headers: await getAxiosHeaders()
    }).catch((err) => {
        return new Promise<AxiosResponse<any, any>>(solve => { solve({ data: { Success: false, ExceptionMessage: `Internal error: ${err.message}` } } as AxiosResponse) })
    })
}
const postWithSession = async (url: string, body: any) => {

    /* region Scramble */
    const _body = await Scramble(url, body, 'POST')
    /* endregion Scramble */

    return AXIOS.post(url, _body, {
        headers: await getAxiosHeaders()
    }).catch((error) => {
        console.log(error)
        const exceptionMessage = error.response?.data?.exceptionMessage
            ?? error.response?.data?.messageError
            ?? "Internal Error";
        return new Promise(solve => { solve({ data: { Success: false, ExceptionMessage: exceptionMessage, AxiosError: error.message } }) })
    })
}

const postFormWithSession = async (url: string, body: any) => {

    const _sessionHeaders = await sessionHeaders()
    const _headers = await getAxiosHeaders()
    const _body = { ..._sessionHeaders, ...body }

    const _bodyFormData = new FormData()

    Object.keys(_body).forEach(function (k) {
        _bodyFormData.append(k, _body[k])
    });

    return AXIOS.post(url, _bodyFormData, {
        headers: { ..._headers, "Content-Type": "multipart/form-data" },
        validateStatus: (status: number) =>
            status >= 200 && status < 400,
    }).catch((error) => {
        console.log(error)
        return new Promise(solve => { solve({ data: { Success: false, ExceptionMessage: 'Internal error', AxiosError: error.message } }) })
    })
}


const postWithSessionStr = async (url: string, body: any) => {
    const _sessionHeaders = await sessionHeaders()

    return AXIOS.post(url, JSON.stringify({ ..._sessionHeaders, ...body }), {
        headers: await getAxiosHeaders()
    }).catch(() => {
        //throw new Error('Server error')
    })
}
const get = async (url: string, params: HTTPPArams, headers?: HTTPPArams) => {
    return AXIOS.get(url, {
        params,
        method: "GET",
        headers: headers
    }).catch((err) => {
        return new Promise<AxiosResponse<any, any>>(solve => { solve({ data: { Success: false, ExceptionMessage: `Internal error: ${err.message}` } } as AxiosResponse) })
    })
}
const post = async (url: string, body: any, headers?: HTTPPArams) => {
    return AXIOS.post(url, { ...body }, {
        headers
    }).catch((error) => {
        console.log(error)
        return new Promise(solve => { solve({ data: { Success: false, ExceptionMessage: 'Internal error', AxiosError: error.message } }) })
    })
}
const getWithoutParams = async (url: string, headers?: HTTPPArams) => {
    return AXIOS.get(url, {   
        method: "GET",
        headers: headers
    }).catch((err) => {
        return new Promise<AxiosResponse<any, any>>(solve => { solve({ data: { Success: false, ExceptionMessage: `Internal error: ${err.message}` } } as AxiosResponse) })
    })
}
export default { AXIOS, get, getAxiosHeaders, sessionHeaders, getWithSession, postWithSession, postWithSessionStr, post, postFormWithSession,getWithoutParams };
